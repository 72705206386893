.product-card__part-of-bundle__tag {
  background-color: transparent;
  border: var(--border-thin) var(--brand-color);
  color: var(--brand-color);
}

.product-card__tag__link {
  padding: 0;
}

