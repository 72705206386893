.cms-article_header {
  .article__campaign-period > * {
    background-color: $color1;
    &:hover {
      background-color: $color3;
    }
  }
  .article__introduction h1 {
    color: $color1;
  }
  .article__ingress {
    color: $color4;
  }
}


.article .cms-article_social_profile {
  border-color: $color6;
  background: transparent;
}

.cms-article_social_profile .article__social-media .social-follow__article:hover {
  background-color: $color3;
  border-color: $color3;
  svg {
    fill: $color5;
  }
}

.article__highligted-text h1,
.article__highligted-text h2,
.article__highligted-text h3,
.article__highligted-text h4,
.article__highligted-text p,
.cms-article_step_by_step .article__step-by-step li:before {
  color: $color5;
}

.article__factbox,
.article__guide {
  background-color: $color6;
}

.article__factbox a svg,
.article__guide a svg {
  fill: $color1;
}

.article__factbox .icon-factbox,
.article__factbox .icon-guide,
.article__guide .icon-factbox,
.article__guide .icon-guide {
  background-color: $color1;
}

.cms-article_body,
.article__step-by-step,
.points {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color4;
  }
}

.points {
  .btn-brand {
    background: $color3;
    color: $color5;
    border-color: $color3;
    &:hover,
    &:focus,
    &:active {
      background-color: $color1;
      color: $color5;
      border-color: $color1;
    }
  }
}

.cms-points .highlight__checkmark {
  height: 60px;
  width: 60px;
  border: var(--border-thin) $color1;

  svg {
    height: 20px;
    width: 20px;
  }
  @media (--tablet-sm) {
    height: 50px;
    width: 50px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
  @media (--mobile) {
    height: 45px;
    width: 45px;
    svg {
      height: 16px;
      width: 16px;
    }
  }
}

.article__blockquote {
  background-color: $color6;
  @media (--tablet-and-above) {
    margin: -5px 10px 0 0;
  }
  svg {
    fill: $color1;
  }
  &__text {
    width: calc(100% - 50px);
    color: $color4;
    font-weight: 700;
  }
}

.article__textbox {
  background-color: $color6;
}

.article__textbox.bgcolor {
  background-color: $color1;
}

.article__offer-banner {
  h1,
  h2,
  h3,
  h4,
  p,
  ul,
  li {
    color: $color5;
  }
  color: $color5;
}

.cms-article_offer_banner .article__offer-banner {
  background-color: $color8;
}

.article__offer-banner__period {
  text-transform: initial;
}

.cms-article_offer_banner .article__offer-banner .btn {
  background-color: $color1;
  &:hover,
  &:focus,
  &:active {
    background-color: $color3;
    color: $color5;
  }
}

.article .a-col .a-block h3 {
  color: $color4;
}

.article .article-list .article-list-item .read-more {
  color: $color4;
  text-transform: initial;
  font-size: 14px;
}

.cms-halfwidth {
  .half-width {
    &__wrapper {
      h2 {
        color: $color5;
        text-transform: none;
        position: relative;
        padding-bottom: 15px;
        &::after {
          content: "";
          width: 20%;

          position: absolute;
          height: 2px;
          left: 0;
          bottom: 5px;
          background-color: var(--primary-color);
          @media (--tablet) {
            width: 25%;
          }
          @media (--mobile) {
            border-width: 1px;
            width: 33%;
          }
        }
      }
      p {
        color: $color5;
      }
      .half-width__text__button {
        justify-content: flex-start;
      }
      .half-width__text {
        .btn {
          margin-left: 0;
          margin-right: 0;
        }
      }
      &__style1 {
        h2::after {
          background-color: var(--offset-of-brand-color);
        }

        .btn-brand {
          background-color: var(--secondary-color);
          color: var(--color-on-secondary-color);
          &:hover,
          &:focus,
          &:active {
            background-color: color-mix(var(--secondary-color), #000, 5%);
            color: var(--color-on-secondary-color);
          }
        }
      }
      &__style2 {
        background-color: var(--secondary-background-color);
        h2 {
          color: $color4;
        }
        p {
          color: $color4;
        }
        .btn-whiteBorder {
          background-color: transparent;
          border-color: $color1;
          color: $color1;
          &:hover,
          &:focus,
          &:active {
            background-color: $color1;
            color: $color5;
            text-decoration: none;
          }
        }
      }

      &__style4 {
        background-color: $color3;
        h2::after {
          background-color: $color5;
        }

        .btn-blackBorder {
          background-color: transparent;
          border-color: $color5;
          color: $color5;
          &:hover,
          &:focus,
          &:active {
            background-color: $color5;
            border-color: $color5;
            color: $color3 !important;
            text-decoration: none;
          }
        }
      }
      &__style-standard {
        h2,
        p {
          color: $color4;
        }
        .half-width__content {
          @media (--mobile) {
            text-align: left;
          }
        }
      }
    }
  }
}

.article__cta,
.article__also-read h2 {
  font-family: $fontFamilyFieldwork !important;
  text-transform: initial;
  &__see-more {
    text-transform: initial;
  }
}

.article .article-list .article-list-item:hover .read-more-arrow {
  fill: $color5;
}

.cms-subpages {
  .heading--2 {
    color: $color1;
  }
}
