.blockbanner {
  background-color: $color6;
}

.blockbanner .heading--2 {
  @media (--tablet-sm-and-less) {
    font-size: smaller;
  }
  &::after {
    content: "";
    border: 2px solid $color1;
    width: 20%;
    display: block;
    margin: 10px auto 5px auto;
    @media (--mobile) {
      border-width: 1px;
      width: 33%;
    }
  }
}
