
  .pageheader-header {
    h1 {
      margin-top: 15px;
    }
    h1::after {
      border: var(--border-thin) $color1;
      content: "";
      width: 25%;
      display: block;
      margin: 5px auto 15px auto;
      @media (--tablet-sm) {
        width: 30%;
        margin-top: 10px;
      }
      @media (--mobile) {
        width: 40%;
        margin-top: 10px;
      }
    }
 
  }

  .productlist__campaign-description .half-width__image {
    border-radius: 0;
  }

  .productlist-footer {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: $color4;
    }
  }

  .productlist {
    &__focus-banner {
      border-color: $gColorBorder1;
    }
    &__campaign {
      background-color: $color6;
      .heading--1,
      p {
        color: $color4;
      }
      .heading--1::after {
        border: var(--border-thin)$color1;
        content: "";
        width: 20%;
        display: block;
        margin: 15px auto 20px auto;

        @media (--mobile) {
          width: 33%;
        }
        @media (--mobile-lg) {
          width: 20%;
        }
      }

      &-text__countdown p {
        background-color: transparent;
        color: $color1;
        border: var(--border-thin) $color1;
      }
    }
  }
