.divider {
  border-color: $gColorBorder1;
}
.drop-down {
  border-radius: 0;
  @media (--tablet-and-above) {
    font-size: 14px;
  }
  text-transform: none;
  &__arrow {
    fill: $color1;
  }
  &__header svg {
    fill: $color1;
  }
  &__wrapper {
    text-transform: inherit !important;
  }
  &__body__item {
    .product-card__variants__image-variant {
      border-radius: 0;
    }
  }
}

a {
  color: $color4;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: $color3;
    text-decoration: underline;
  }
}

.form-control:focus {
  border-color: $color7 !important;
  box-shadow: 0 2px 10px 1px rgba(153, 153, 153, 0.5);
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-indicator {
    background-color: $color5;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23003961'/%3E%3C/svg%3E");
    border-color: $color3;
  }
}

.custom-control-description,
.text-small {
  font-size: 15px !important;
  line-height: 25px;
  @media (--mobile) {
    font-size: 14px !important;
    line-height: 24px;
  }
}

.custom-radio .custom-control-indicator,
input[type="checkbox"],
input[type="radio"] {
  border-color: $gColorBorder1;
}

.product-stock__status {
  box-shadow: none;
  height: 8px;
  margin-bottom: 0;
  width: 8px;
  &--in_stock {
    background-color: #4caf50;
    box-shadow: none;
  }
}
