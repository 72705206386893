.aa-datasets-not-products .aa-dataset-brands .aa-suggestion > a,
.aa-datasets-not-products .aa-dataset-groups .aa-suggestion > a {
  background-color: $color6;
  color: $color4;
  border: var(--border-thin) $color6;
  &:hover {
    background-color: $color1;
    color: $color5;
    border-color: $color1;
  }
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a {
  color: $color4;
  border-color: $color6;
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a .algolia-autocomplete__description__read-more svg,
.algolia-autocomplete__splash .splash {
  text-transform: initial;
  background-color: $color1;
}

.aa-suggestion em,
.aa-suggestion > a .algolia-autocomplete__price {
  color: $color1;
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a:hover .algolia-autocomplete__description__read-more svg {
  fill: $color5;
  background-color: $color3;
}

.algolia-autocomplete__description__read-more {
  text-transform: initial;
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a .algolia-autocomplete__description h4 {
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0;
  word-spacing: 0;
  -webkit-line-clamp: 5;
  max-height: 138px;
  @media (--tablet-sm) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (--mobile) {
    -webkit-line-clamp: 3;
    font-size: 18px;
    line-height: 24px;
  }
}

.aa-datasets-not-products {
  border-radius: 0;
}

.aa-datasets-not-products .aa-dataset-inspiration .aa-suggestion > a .algolia-autocomplete__description__read-more {
  color: $color4;
  text-transform: initial;
  font-size: 12px;
}

.aa-datasets-not-products .aa-dataset-brands a,
.aa-datasets-not-products .aa-dataset-groups a {
  text-transform: none;
}

.algolia-autocomplete__description__text {
  display: none !important;
}

.aa-suggestion em {
  color: $color4;
}

.heading--4 {
  font-size: 20px;
  line-height: 30px;
  @media (--mobile) {
    font-size: 16px;
    line-height: 26px;
  }
  @media (--mobile-sm) {
    font-size: 14px;
    line-height: 24px;
  }
}

.aa-dropdown-menu__overlay {
  @media (--mobile) {
    top: 76px;
  }
}
.aa-datasets {
  @media (--mobile) {
    margin: 0;
    width: calc(100vw - 20px);
  }
}

.aa-suggestion .algolia-autocomplete__splash > * {
  border: var(--border-thin) var(--brand-color) !important;
  background-color: var(--white);
  color: var(--brand-color);
}
