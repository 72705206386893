.product-card {
  &__current-price {
    color: $color1;
  }
  &__alternative-product__info__price,
  &__current-price,
  &__extraordinary-savings {
    font-weight: var(--font-weight-price);
    .product-card__savings {
      padding: 0 10px 0 10px;
      background-color: var(--color-brand-700);
    }
  }
  &__extraordinary-savings {
    background-color: var(--color-brand-700);
    font-weight: var(--font-weight-on-splash-notification);
  }

  &__enlarged {
    .drop-down {
      border-color: $gColorBorder1;
    }
  }
  &__maininfo {
    .drop-down {
      border-color: $color5;
      &:hover,
      &:focus {
        border-color: $color1;
      }
    }
    &--not-for-sale {
      .product-card__not-for-sale {
        font-weight: 700;
        background-color: $color6;
        border-radius: 0 0 14px 14px;
        padding: 15px 15px 40px 15px;
        @media (--mobile) {
          padding: 10px 10px 35px 10px;
        }
        &__wrapper {
          border-radius: 0;
          margin: 0;
        }
      }
    }
  }
  &__savings {

    &__expiry {
      background-color: var(--before-price);
      color: var(--color-on-primary-color);
    }
  }
  &__offer-expiry {
    font-family: $fontFamilyFieldwork;
  }

  &__maininfo .drop-down__open .drop-down__see-more-header,
  &__maininfo .drop-down__see-more-header {
    background-color: $color5 !important;
    border-color: var(--secondary-background-color) !important;
    color: $color4 !important;
    @media (--desktop) {
      font-size: 14px;
    }
    @media (--tablet-lg) {
      font-size: 13px;
    }
    @media (--tablet-sm) {
      font-size: 12px;
    }
    text-transform: none;
  }
  &__maininfo .drop-down:hover .drop-down__see-more-header {
    border-color: $color1 !important;
  }

  &__CTA {
    &__add-to-basket {
      &:hover {
        background-color: var( --cta-hover-color);
        color: $color5;
      }
      span {
        font-weight: 400;
      }
    }
  }
  &__volume-discount {
    .custom-radio .custom-control-indicator,
    input[type="checkbox"],
    input[type="radio"] {
      border-color: $color5;
    }
    .custom-radio {
      .custom-control-input:checked ~ .custom-control-indicator {
        border-color: $color3;
      }
    }
  }
  &__short-item-text,
  &__content__description__text {
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      color: $color4;
    }
    &__read-more {
      text-transform: initial;
      color: $color4;
      font-size: 14px;
      line-height: normal;
      &:hover {
        color: $color3;
      }
      @media (--mobile) {
        font-size: 12px;
      }
    }
  }

  &__content {

    &__toggle {
      fill: $color1;
    }
    &__body__grid {
      font-family: $fontFamilyFieldwork;
    }
    &__specifications__grid__specification p {
      font-size: 15px;
      line-height: 25px;
      text-transform: initial;
      font-weight: 400;
      @media (--mobile-sm) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  &__variants {
    &__price {
      color: $color1;
    }
    &__savings {
      background-color: $color1;
      font-weight: 400;
    }
    &__drop-down,
    &__selected {
      background-color: $color3;
      svg {
        fill: $color5;
      }
    }
    &__image-variant__active {
      border-color: $color3;
    }
  }
}
