.footer {
  border: none;
  .footer {
    &__top,
    &__usp,
    &__usp a,
    &__mid {
      background-color: $color1;
      color: var(--white);
      .container {
        border-color: $color8;
      }

      &__item {
        background-color: $color8;
        color: var(--white);
        border: none;
      }
    }
    &__usp,
    &__usp a {
      color: $color5;
      &:hover,
      &:focus,
      &:active {
        text-decoration: initial;
        color: $color5;
      }
      @media (--mobile) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &__bot {
      background-color: $color8;
      border: none;
      @media (--mobile) {
        padding-bottom: 60px;
      }
    }
    &__nav-link {
      &:hover,
      &:focus,
      &:active {
        color: $color5;
        text-decoration: underline;
      }
    }
    &__list {
      border-color: $color8;
      &-item {
        margin: 0;
      }
      &-section * {
        color: var(--white);
      }
    }
    &__nav-icon {
      top: 16px;
    }
    &__logo-img {
      width: 200px;

      @media (--tablet-and-above) {
        margin: 0;
      }
      @media (--tablet-sm) {
        width: 180px;
        margin: 0;
        padding-top: 2px;
      }
    }
    &__contact__block__link:hover {
      .footer__contact--icon {
        background-color: $color8;
        border-color: $color8;
      }
    }

    &__contact-and-social {
      @media (--mobile) {
        padding-bottom: 0;
      }
    }
    &__text {
      color: $color5;
    }
    &__news-letter {
      border-color: $color8;
      .btn-brand {
        background-color: var(--offset-of-brand-color-darker);
        color: var(--white);
        font-weight: 400;
        &:hover,
        &:focus,
        &:active {
          background-color: var(--secondary-color);
        }
      }
      .btn {
        font-weight: 400;
      }
    }
  }
  &__list-section {
    @media (--mobile) {
      padding: 0;
      .heading--3,
      h3 {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }
}
