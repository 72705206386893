// Colors
$color1: #a32c22; //Red
$color2: #e24851; //Pink
$color3: #003961; //Blue
$color4: #2b2b2b; //Black
$color5: #ffffff; //White
$color6: #e9eae8; //Grey backgrounds
$color7: #999999; //Grey text
$color8: #752019; //Dark red
$color9: #163246; //primary hover of blue color
$color10: #2aa172; //secondary hover of main color
$color11: #2b2b2b; // black

// Backgrounds
$gColorbg1: #2b2b2b;
$colorBg1: #f5f5f5; // Lightgrey - Body color - Primary grey
$colorBg2: #ffffff; // White - Product bg
$colorBg3: #ebebeb; // Grey - aside filters
$colorBg4: #ffe787; //offer yellow
$colorBg5: #7ea832; //btn hover gradient
$colorBg6: #96c300; // green
$colorBg7: #2b2b2b; // black
$colorBg8: #96c300; // green
$colorBg9: #a3d404; // lighter green
$colorBg10: #e1e1e1; // Secondary grey
$colorBg11: #dedede; // Tertiary grey

// Borders
$colorBorder1: #d6d5d5; // Grey
$colorBorder2: #d9d9d9; // Dark(er) grey
$colorBorder3: #c6c6c6; // Dark grey (search inupt)
$colorBorder4: #2e87ab; // Lightblue
$colorBorder5: #2b2b2b; // Darkest
$colorBorder6: #2b2b2b; // Darkest
$colorBorder11: #aeead2; // Very light green

$borderThin: 1px solid;
$borderMedium: 2px solid;
$borderThick: 3px solid;

// Font
$colorFont1: #2b2b2b; // primary - grey
$colorFont2: #2b2b2b; // blackish
$colorFont3: #ffffff; // White (productlist btn)
$colorFont4: #666666; // lightgrey - footer usp  hover on links

$fontFamilyFieldwork: Outfit, sans-serif;
$fontFamilyRobotoCon: Outfit, sans-serif;
$fontFamilyOswald: Outfit, sans-serif;
$fontFamilyArial: Arimo, sans-serif;

$gColorFont1: #2b2b2b;
$gColorbg1: #2b2b2b;
