.landingpage__topbanner__button,
.landingpage__videotopbanner__button {
  margin-right: 0;
  margin-left: 0;
}

.landingpage__campaign-banner {
  font-size: 18px;
  line-height: 24px;
  line-height: normal;
  @media (--tablet-sm) {
    font-size: 16px;
    line-height: 21px;
  }
  @media (--mobile) {
    font-size: 12px;
    line-height: 16px;
  }
  @media (--mobile-lg) {
    font-size: 14px;
    line-height: 18px;
  }
}
.landingpage__topbanner,
.landingpage__videotopbanner {
  a {
    text-decoration: none !important;
  }
  &__half-image {
    width: 50%;
    display: block;
    @media (--mobile-md) {
      width: auto;
    }
  }
  &__overlay {
    display: flex;
    flex-direction: row;
  }
  &__caption {
    h1,
    .heading--1,
    .heading,
    p {
      margin-left: 0;
      margin-right: 0;
      text-transform: none;
      max-height: none;
      background-color: transparent;
      padding: 0;
      color: $color5;
      @media (--desktop) {
        margin-bottom: 30px;
      }
      @media (--tablet-lg) {
        margin-bottom: 25px;
      }
      @media (--tablet-sm) {
        margin-bottom: 20px;
      }
      @media (--tablet-xs-and-less) {
        margin-bottom: 15px;
      }
      @media (--mobile-md) {
        margin-bottom: 10px;
      }
    }
    h1,
    .heading--1 {
      margin-left: 0;
      margin-right: 0;
      font-weight: var(--font-weight-bold);
      font-family: $fontFamilyFieldwork;
      position: relative;
      overflow: visible;
      padding: 0;
      &::after {
        content: "";
        background-color: var(--brand-color);
        left: 0;
        bottom: -15px;
        width: 33%;
        max-width: 270px;
        height: 3px;
        position: absolute;
        @media (--tablet-lg) {
          bottom: -10px;
        }
        @media (--tablet-sm-and-less) {
          bottom: -7px;
          height: 2px;
        }

      
      }
    }
    &--dark {
      color: $color4;
      h1,
      .heading--1,
      .heading,
      p {
        color: $color4;
      }
    }
    &--half-width {
      h1:after {
        left: 0;
        right: 0;
      }

      h1::after {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  &__overlay {
    @media (--mobile-md) {
      flex-direction: column-reverse;
    }
  }
}

.landingpage__topbanner__nav,
.landingpage__videotopbanner__nav {
  left: -80px;
}

.landingpage__topbanner__nav--next,
.landingpage__videotopbanner__nav--next {
  left: auto;
  right: -80px;
}
